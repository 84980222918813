<template>
  <v-dialog
    width="700px"
    v-model="isShowModal"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="px-5">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.teacherFee")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="px-4">
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              hide-details
              flat
              outlined
              class="elevation-0"
              background-color="inputColor"
              :label="$_t('attribute.teachers')"
              dense
              :items="teachers"
              v-model="teacherPublicKey"
              clearable
              :item-text="
                (item) =>
                  `${item.fullName} | ${item.nickName} | ${item.state} | ${$_t(
                    `attribute.${item.teacherType}`
                  )}`
              "
              item-value="publicKey"
            >
              <template v-slot:prepend-item>
                <div class="px-3 py-2">
                  <v-text-field
                    outlined
                    :label="$_t('attribute.searchTeacher')"
                    dense
                    hide-details
                    @input="fetchTeachers($event)"
                  />
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col v-if="!readonly">
            <v-spacer />
            <v-btn
              class="float-end"
              v-if="teacherPublicKey"
              @click="addToForm"
              fab
              color="primary"
              small
              x-small
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <template v-if="teacherPublicKey">
          <v-row v-for="(form, index) in forms" :key="index">
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="5">
              <DataTimePicker
                v-model="form.from"
                :label="$_t('attribute.from')"
                append-icon="mdi-calendar"
                readonly
              />
            </v-col>
            <v-col cols="5">
              <DataTimePicker
                v-model="form.to"
                :label="$_t('attribute.to')"
                append-icon="mdi-calendar"
                readonly
              />
            </v-col>
            <v-col cols="2" v-if="!readonly">
              <v-btn
                v-if="forms.length > 1"
                class="float-end"
                @click="removeForm(index)"
                fab
                dark
                color="red"
                small
                x-small
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    v-if="!readonly"
                    dark
                    block
                    v-bind="attrs"
                    v-on="on"
                  >
                    Add level
                  </v-btn>
                </template>
                <template v-slot:default>
                  <v-card max-height="400px">
                    <v-list>
                      <v-list-item
                        v-for="(level, levelIndex) in fee_levels"
                        :key="levelIndex"
                        @click="setLevelToForm(level.value, index)"
                      >
                        <v-list-item-title>{{ level.text }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(level, levelIndex) in fee_levels"
                    :key="levelIndex"
                    @click="setLevelToForm(level.value, index)"
                  >
                    <v-list-item-title>{{ level.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
            <template
              v-for="(value, key, formLevelIndex) in form.feeInfoOfLevels"
            >
              <v-col
                v-if="form.feeInfoOfLevels[key].length"
                :key="formLevelIndex"
                cols="12"
              >
                <v-row>
                  <v-col cols="10" class="pb-0">{{
                    $_t(`attribute.${key}`)
                  }}</v-col>
                  <v-col cols="2" v-if="!readonly" class="pb-0">
                    <v-btn
                      x-small
                      class="float-end"
                      @click="popLevel(key, index)"
                      dark
                      fab
                      color="red"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="12">{{
                    $_t(`attribute.numberOfStudentsInAClass`)
                  }}</v-col>
                </v-row>
                <v-row v-for="(inner, innerIndex) in value" :key="innerIndex">
                  <v-col cols="3" class="pt-0">
                    <v-text-field
                      type="number"
                      :label="$_t('attribute.students')"
                      dense
                      :readonly="readonly"
                      hide-details
                      outlined
                      v-model.number="inner.studentsCountFrom"
                    />
                  </v-col>
                  <v-col
                    cols="1"
                    class="d-flex justify-center align-center pt-0"
                  >
                    To
                  </v-col>
                  <v-col cols="3" class="pt-0">
                    <v-text-field
                      type="number"
                      :label="$_t('attribute.students')"
                      dense
                      :readonly="readonly"
                      hide-details
                      outlined
                      v-model.number="inner.studentsCountTo"
                    />
                  </v-col>
                  <v-col cols="5" class="pt-0">
                    <v-text-field
                      type="number"
                      :label="$_t('attribute.fee')"
                      dense
                      :readonly="readonly"
                      hide-details
                      outlined
                      v-model.number="inner.feeAmount"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </template>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12">
          <div class="d-flex justify-end">
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="close">
              {{ $_t("attribute.cancel") }}
            </v-btn>

            <v-btn
              color="primary"
              class="ml-4"
              @click="submit"
              :loading="is_loading"
              :disabled="is_loading || readonly"
            >
              {{ $_t("attribute.save") }}
            </v-btn>
          </div>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import message from "@/tools/Message";
import DataTimePicker from "@/components/app/form/DataTimePicker";
import { _t } from "@/tools/Utils";
export default {
  name: "UpsertFee",
  components: { DataTimePicker },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
      default: false,
    },
    publicKey: {
      type: String,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fee_levels: [
        { text: _t("attribute.GCSE_Online"), value: "GCSE | Online" },
        { text: _t("attribute.GCSE_Offline"), value: "GCSE | Offline" },
        { text: _t("attribute.A_LEVEL_Online"), value: "A_LEVEL | Online" },
        { text: _t("attribute.A_LEVEL_Offline"), value: "A_LEVEL | Offline" },
        { text: _t("attribute.KS_3_Online"), value: "KS_3 | Online" },
        { text: _t("attribute.KS_3_Offline"), value: "KS_3 | Offline" },
        {
          text: _t("attribute.English_Elementary_Online"),
          value: "English_Elementary | Online",
        },
        {
          text: _t("attribute.English_Elementary_Offline"),
          value: "English_Elementary | Offline",
        },
        {
          text: _t("attribute.AdditionalExamination_Online"),
          value: "AdditionalExamination | Online",
        },
        {
          text: _t("attribute.AdditionalExamination_Offline"),
          value: "AdditionalExamination | Offline",
        },
        {
          text: _t("attribute.University_Online"),
          value: "University | Online",
        },
        {
          text: _t("attribute.University_Offline"),
          value: "University | Offline",
        },
        {
          text: _t("attribute.Contest_Online"),
          value: "Contest | Online",
        },
        {
          text: _t("attribute.Contest_Offline"),
          value: "Contest | Offline",
        },
        {
          text: _t("attribute.IELTS_Online"),
          value: "IELTS | Online",
        },
        {
          text: _t("attribute.IELTS_Offline"),
          value: "IELTS | Offline",
        },
        {
          text: _t("attribute.TOEFL_Online"),
          value: "TOEFL | Online",
        },
        {
          text: _t("attribute.TOEFL_Offline"),
          value: "TOEFL | Offline",
        },
        {
          text: _t("attribute.IB_Online"),
          value: "IB | Online",
        },
        {
          text: _t("attribute.IB_Offline"),
          value: "IB | Offline",
        },
        {
          text: _t("attribute.AP_Online"),
          value: "AP | Online",
        },
        {
          text: _t("attribute.AP_Offline"),
          value: "AP | Offline",
        },
      ],
      menu: false,
      menu2: false,
      teacherPublicKey: null,
      forms: [
        {
          from: null,
          to: null,
          feeInfoOfLevels: {
            "GCSE | Online": [],
            "GCSE | Offline": [],
            "A_LEVEL | Online": [],
            "A_LEVEL | Offline": [],
            "KS_3 | Online": [],
            "KS_3 | Offline": [],
            "English_Elementary | Online": [],
            "English_Elementary | Offline": [],
            "AdditionalExamination | Online": [],
            "AdditionalExamination | Offline": [],
            "University | Online": [],
            "University | Offline": [],
            "Contest | Online": [],
            "Contest | Offline": [],
            "IELTS | Online": [],
            "IELTS | Offline": [],
            "TOEFL | Online": [],
            "TOEFL | Offline": [],
            "IB | Online": [],
            "IB | Offline": [],
            "AP | Online": [],
            "AP | Offline": [],
          },
        },
      ],
      initValue: {
        "GCSE | Online": [],
        "GCSE | Offline": [],
        "A_LEVEL | Online": [],
        "A_LEVEL | Offline": [],
        "KS_3 | Online": [],
        "KS_3 | Offline": [],
        "English_Elementary | Online": [],
        "English_Elementary | Offline": [],
        "AdditionalExamination | Online": [],
        "AdditionalExamination | Offline": [],
        "University | Online": [],
        "University | Offline": [],
        "Contest | Online": [],
        "Contest | Offline": [],
        "IELTS | Online": [],
        "IELTS | Offline": [],
        "TOEFL | Online": [],
        "TOEFL | Offline": [],
        "IB | Online": [],
        "IB | Offline": [],
        "AP | Online": [],
        "AP | Offline": [],
      },
    };
  },
  watch: {
    isShowModal(value) {
      if (value) this.fetchTeachers();
      if (this.publicKey) {
        this.teacherPublicKey = this.publicKey;
      }
    },
    async teacherPublicKey(value) {
      if (!value) return;
      const res = await this.$actions.getATeacherTeachingFee({
        publicKey: value,
      });
      if (res) {
        this.teacherPublicKey = res.publicKey;
        this.forms = res.feeInfo.map((i) => ({
          ...i,
          feeInfoOfLevels: {
            ...this.initValue,
            ...i.feeInfoOfLevels,
          },
          from: new Date(i.from * 1000).toISOString(),
          to: new Date(i.to * 1000).toISOString(),
        }));
      } else {
        this.forms = [
          {
            from: null,
            to: null,
            feeInfoOfLevels: {
              ...this.initValue,
            },
          },
        ];
      }
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      role: "authentication/role",
      teachers: "admin/get_teachers",
    }),
  },
  methods: {
    async submit() {
      if (!this.teacherPublicKey)
        return message.error(this.$_t("SELECT", { name: "teacher" }));
      if (this.forms.every((f) => !f.from || !f.to))
        return message.error(this.$_t("FILL_CORRECT"));
      const res = await this.$actions.upsertTeachingFee({
        publicKey: this.teacherPublicKey,
        feeInfo: this.forms,
      });
      if (res) {
        this.$emit("update");
        this.close();
      }
    },
    close() {
      this.teacherPublicKey = null;
      this.forms = [
        {
          from: null,
          to: null,
          feeInfoOfLevels: {
            ...this.initValue,
          },
        },
      ];
      this.$emit("close");
    },
    async fetchTeachers(search) {
      await this.$actions.getUsers({
        all: true,
        role: "Teacher",
        search,
        dispatch: "admin/setTeachers",
        ignoreRoleFilter: ["AcademicConsultant", "FinancialAdmin"].includes(
          this.role
        ),
      });
    },
    addToForm() {
      this.forms.push({
        from: null,
        to: null,
        feeInfoOfLevels: {
          ...this.initValue,
        },
      });
    },
    removeForm(index) {
      this.forms.splice(index, 1);
    },
    popLevel(key, index) {
      this.forms[index].feeInfoOfLevels[key].pop();
    },
    setLevelToForm(level, index) {
      this.forms[index].feeInfoOfLevels[level].push({
        studentsCountFrom: 0,
        studentsCountTo: 0,
        feeAmount: 0,
      });
    },
  },
};
</script>

<style scoped></style>
