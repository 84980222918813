//ADD_SETTLEMENT
const { required } = require("vuelidate/lib/validators");

export const ADD_GROUP_SALARY = {
  /* Form SCHEMA */
  schema: [
    {
      id: "toTime",
      label: "toTime",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "datetime",
    },
  ],

  /* Form MODEL */
  model: {
    toTime: null,
  },
  validations: {
    form: {
      toTime: { required },
    },
  },
};
