import { _t } from "@/tools/Utils";

export const TRANSFER_TABLE_HEADER = {
  headers: [
    {
      text: _t("attribute.studentName"),
      align: "start",
      sortable: false,
      value: "studentName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.sourceClassroomName"),
      align: "start",
      sortable: false,
      value: "sourceClassroomName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.sourceClassroomTeacherFullName"),
      align: "start",
      sortable: false,
      value: "sourceClassroomTeacherFullName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.sourceClassroomCharge"),
      align: "start",
      sortable: false,
      value: "sourceClassroomCharge",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.sourceClassroomIncomeInHour"),
      align: "start",
      sortable: false,
      value: "sourceClassroomIncomeInHour",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.destinationClassroomName"),
      align: "start",
      sortable: false,
      value: "destinationClassroomName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.destinationClassroomTeacherFullName"),
      align: "start",
      sortable: false,
      value: "destinationClassroomTeacherFullName",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.destinationClassroomCharge"),
      align: "start",
      sortable: false,
      value: "destinationClassroomCharge",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.destinationClassroomIncomeInHour"),
      align: "start",
      sortable: false,
      value: "destinationClassroomIncomeInHour",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.description"),
      align: "start",
      sortable: false,
      value: "description",
      class: "text--disabled text-subtitle-1",
      cellClass: "",
    },
    {
      text: _t("attribute.createMoment"),
      sortable: false,
      value: "createMoment",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.amount"),
      sortable: false,
      value: "amount",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.edit"),
      sortable: false,
      value: "edit",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.delete"),
      sortable: false,
      value: "delete",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.confirm"),
      sortable: false,
      value: "confirm",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
    {
      text: _t("attribute.confirmationDate"),
      sortable: false,
      value: "confirmationDate",
      class: "text--disabled text-subtitle-1",
      cellClass: " text--secondary",
    },
  ],
};
