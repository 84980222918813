<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="6" sm="4" class="pt-5">
        <TopTitle :title="$_t('attribute.transfers')" />
      </v-col>
      <v-col cols="12" md="4">
        <v-select
          hide-details
          outlined
          :label="$_t('attribute.isConfirmed')"
          dense
          background-color="#fff"
          :items="[
            { text: $_t('attribute.confirmed'), value: true },
            { text: $_t('attribute.notConfirmed'), value: false },
          ]"
          v-model="isConfirmed"
          @change="init({})"
          clearable
          item-text="text"
          item-value="value"
        >
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="
            pageInfo.page = 1;
            init({ search: $event });
          "
          :headers="headers"
          :items="transfers"
          :page-info="pageInfo"
          :loading="is_loading"
        >
          <template v-slot:top-right>
            <v-dialog ref="menu2" v-model="menu" width="300px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary">
                  {{ $_t("attribute.export") }}
                </v-btn>
              </template>
              <template v-slot:default>
                <v-card>
                  <v-date-picker
                    width="300px"
                    color="primary"
                    elevation="0"
                    type="month"
                    v-model="date"
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu = false">
                      {{ $_t("attribute.cancel") }}
                    </v-btn>
                    <v-btn color="primary" @click="exportTransfer">
                      {{ $_t("attribute.export") }}
                    </v-btn>
                  </v-date-picker>
                </v-card>
              </template>
            </v-dialog>
          </template>
          <template v-slot:description="{ item }">
            <ShowLessonDescription
              v-if="item.description"
              :text="$_t('attribute.description')"
              :description="item.description"
            />
          </template>
          <template v-slot:createMoment="{ item }">
            {{ $_date(item.createMoment, "long") }}
          </template>
          <template v-slot:confirmationDate="{ item }">
            {{
              item.confirmationDate ? $_date(item.confirmationDate, "long") : ""
            }}
          </template>
          <template v-slot:edit="{ item }">
            <AddTransfer :user="item" :transfer="item" @init="init({})">
              <template #activator>
                <v-btn icon color="primary">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
            </AddTransfer>
          </template>
          <template v-slot:delete="{ item }">
            <v-btn icon @click="openDeleteTransfer(item)" color="red">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <template v-slot:confirm="{ item }">
            <v-btn icon @click="confirmTransfrModal(item)">
              <v-icon
                color="green"
                v-if="
                  item?.confirmationDetail?.confirmed &&
                  item?.confirmationDetail?.mainConfirmed
                "
                >mdi-check</v-icon
              >
              <v-icon color="red" v-else>mdi-close</v-icon>
            </v-btn>
          </template>
          <template v-slot:pagination>
            <v-pagination
              v-model="pageInfo.page"
              :length="pageInfo.pageCount"
              :total-visible="5"
              @input="init({})"
            ></v-pagination>
          </template>
        </MainTable>
        <ConfirmDelete ref="deleteTransfer" @confirm="deleteTransfer">
        </ConfirmDelete>
        <ConfirmTransfer
          :transfer="transfer"
          :confirmation-detail="confirmationDetail"
          @confirm="init({})"
          ref="confirmTransfer"
        ></ConfirmTransfer>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import MainTable from "@/packages/admin/components/MainTable";
import { mapGetters } from "vuex";
import { reactive, ref } from "vue";
import ConfirmDelete from "@/components/app/ConfirmDelete";
import { TRANSFER_TABLE_HEADER } from "@/packages/finance/schema/table/TRANSFER_TABLE.schema";
import AddTransfer from "@/packages/finance/components/transfer/AddTransfer";
import message from "@/tools/Message";
import ShowLessonDescription from "@/packages/admin/components/lessons/ShowLessonDescription";
import ConfirmTransfer from "@/packages/finance/components/transfer/ConfirmTransfer";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import moment from "moment";
import FileDownload from "js-file-download";

export default {
  name: "TransferTable",
  components: {
    ConfirmTransfer,
    ShowLessonDescription,
    AddTransfer,
    ConfirmDelete,
    MainTable,
    TopTitle,
  },
  computed: {
    ...mapGetters({
      transfers: "transfer/transfers",
      is_loading: "loading/is_loading",
      role: "authentication/role",
    }),
  },
  data() {
    return {
      search: "",
      transfer: null,
      confirmationDetail: {
        mainConfirmed: false,
        confirmed: false,
        confirmationMessage: "",
        confirmedBy: null,
      },
      isConfirmed: null,
      date: null,
      menu: false,
    };
  },
  setup() {
    const lessonType = ref(null);
    const baseType = ref(null);
    const headers = TRANSFER_TABLE_HEADER.headers;
    const pageInfo = reactive({
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
    });
    return {
      lessonType,
      baseType,
      headers,
      pageInfo,
    };
  },
  methods: {
    async init({ search = this.search }) {
      this.search = search;
      const { pageCount } = await this.$actions.getAllTransfers({
        page: this.pageInfo.page,
        limit: this.pageInfo.itemsPerPage,
        search: this.search,
        dispatch: "transfer/setTransfers",
        isConfirmed: this.isConfirmed,
      });
      this.pageInfo.pageCount = pageCount;
    },
    editTransfer() {
      this.$emit("edit", this.transfer);
    },
    deleteTransfer() {
      this.$emit("delete", this.transfer);
    },
    openDeleteTransfer(item) {
      if (!["Admin", "AcademicConsultant"].includes(this.role))
        return message.error(this.$_t("ACCESS_DENIED"));
      if (item.confirmed) return message.error(this.$_t("ACCESS_DENIED"));
      this.transfer = item;
      this.$refs.deleteTransfer.open();
    },
    async confirmTransfer() {
      await this.$actions.confirmTransfer({
        transferId: this.transfer.transferId,
        shouldConfirm: true,
      });
      await this.init({});
    },
    confirmTransfrModal(transfer) {
      this.transfer = { ...transfer };
      this.confirmationDetail = { ...transfer.confirmationDetail };
      this.$refs.confirmTransfer.open();
    },
    async exportTransfer() {
      await api
        .get(
          urls.qs("exportTransfer", {
            filter: this.search,
            year: moment(this.date).year(),
            month: moment(this.date).month() + 1,
          }),
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          FileDownload(res, `${this.date}-TransferReport.xlsx`);
        });
      this.date = null;
      this.menu = false;
    },
  },
  created() {
    this.init({});
  },
};
</script>
