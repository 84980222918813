<template>
  <v-dialog
    width="600px"
    v-model="isShowModal"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-on="on" v-bind="attrs">
        <slot name="activator" />
      </div>
    </template>
    <template #default>
      <v-card class="px-4">
        <v-toolbar class="elevation-0" color="transparent">
          <span class="text-h5 font-weight-bold">{{
            transfer
              ? $_t("attribute.editTransfer")
              : $_t("attribute.addTransfer") +
                ` | ${user.fullName} ${user.nickName}`
          }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <MainSoftForm>
          <template v-slot:body>
            <MyFormGenerator
              lg="10"
              :schema="schema"
              :validator="$v"
              :enter="submit"
            ></MyFormGenerator>
          </template>
          <template v-slot:action>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  {{ $_t("attribute.cancel") }}
                </v-btn>

                <v-btn
                  color="primary"
                  class="ml-4"
                  @click="submit"
                  :loading="is_loading"
                >
                  {{ transfer ? $_t("attribute.edit") : $_t("attribute.add") }}
                </v-btn>
              </div>
            </v-col>
          </template>
        </MainSoftForm>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { ADD_TRANSFER } from "@/packages/finance/schema/ADD_TRANSFER.schema";

export default {
  name: "AddTransfer",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    transfer: {
      default: null,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShowModal: false,
      form: ADD_TRANSFER.model,
      schema: ADD_TRANSFER.schema,
    };
  },
  watch: {
    async isShowModal(value) {
      if (value) {
        const balances = await this.$actions.getBalanceOfClassroomOfStudent({
          publicKey: this.user.publicKey || this.transfer.studentPublicKey,
        });
        this.schema[this.getIdWithName(this.schema, "sourceClassroom")].items =
          balances;
        this.schema[
          this.getIdWithName(this.schema, "destinationClassroom")
        ].items = balances;
        if (this.transfer) {
          console.log(this.transfer);
          const {
            destinationClassroomId,
            sourceClassroomId,
            description,
            amount,
          } = this.transfer;
          this.form.sourceClassroom = sourceClassroomId;
          this.form.destinationClassroom = destinationClassroomId;
          this.form.amount = amount;
          this.form.description = description;
          this.schema[
            this.getIdWithName(this.schema, "sourceClassroom")
          ].disabled = true;
          this.schema[
            this.getIdWithName(this.schema, "destinationClassroom")
          ].disabled = true;
        } else {
          this.schema[
            this.getIdWithName(this.schema, "sourceClassroom")
          ].disabled = false;
          this.schema[
            this.getIdWithName(this.schema, "destinationClassroom")
          ].disabled = false;
        }
      } else {
        this.clear();
      }
    },
  },
  validations: {
    ...ADD_TRANSFER.validations,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },
  methods: {
    async submit() {
      try {
        console.log(this.form);
        if (!this.isFormValidate()) return;
        let res = null;
        if (this.transfer) {
          res = await this.$actions.editTransfer({
            trasferId: this.transfer.transferId,
            amount: parseFloat(this.form.amount),
            description: this.form.description,
          });
        } else {
          res = await this.$actions.crateTransfer({
            studentPublicKey: this.user.publicKey,
            studentName: `${this.user.fullName} | ${this.user.nickName}`,
            sourceClassroomId: this.form.sourceClassroom.classroomId,
            sourceClassroomName: this.form.sourceClassroom.classroomName,
            destinationClassroomId: this.form.destinationClassroom.classroomId,
            destinationClassroomName:
              this.form.destinationClassroom.classroomName,
            amount: parseFloat(this.form.amount),
            description: this.form.description,
          });
        }

        if (res) {
          this.$emit("init");
          return this.close();
        }
      } catch (e) {
        console.log(e);
      }
    },
    close() {
      this.isShowModal = false;
      this.$emit("close");
      this.clear();
    },
    clear() {
      this.form.amount = null;
      this.form.destinationDepositId = null;
      this.form.sourceDepositId = null;
      this.$v.$reset();
    },
  },
};
</script>
