<template>
  <v-row>
    <v-col cols="12">
      <UsersTableSection
        ref="userTable"
        role="Student"
        hide-add-balance
        @wallet="seeWallet"
        @contract="seeContractBalance"
      >
        <template #transfer="{ item }">
          <AddTransfer :user="item" @init="initTransfers">
            <template #activator>
              <v-btn color="primary" dark class="ml-2">
                {{ $_t("attribute.transferBalance") }}
              </v-btn>
            </template>
          </AddTransfer>
        </template>
        <template #changeAcademicConsultant="{ item }">
          <v-btn
            v-if="['Admin', 'AcademicConsultant'].includes(role)"
            color="primary"
            @click="changeConsultantModal(item)"
            dark
            class="ml-2"
          >
            {{ $_t("attribute.changeConsultant") }}
          </v-btn>
        </template>
      </UsersTableSection>
    </v-col>
    <v-col cols="12">
      <TransferTable
        ref="transferTable"
        @delete="deleteTransfer"
      ></TransferTable>
    </v-col>
    <ChangeConsultant
      @update="$refs.userTable.initUsers({})"
      ref="changeConsultant"
    />
    <Balances
      :is-show-modal="balancesModal"
      :balance="balance"
      @close="balancesModal = false"
    />
    <Contracts
      @close="contractModal = false"
      :contracts="contracts"
      :is-show-modal="contractModal"
    />
  </v-row>
</template>

<script>
import UsersTableSection from "@/packages/finance/components/UsersTableSection";
import message from "@/tools/Message";
import Balances from "@/packages/finance/components/deposit/Balances";
import Contracts from "@/packages/finance/components/deposit/Contracts";
import AddTransfer from "@/packages/finance/components/transfer/AddTransfer";
import TransferTable from "@/packages/finance/components/transfer/TransferTable";
import { mapGetters } from "vuex";
import ChangeConsultant from "@/packages/finance/components/transfer/ChangeConsultant";
export default {
  name: "transfer",
  components: {
    ChangeConsultant,
    TransferTable,
    AddTransfer,
    Contracts,
    Balances,
    UsersTableSection,
  },
  data() {
    return {
      balance: null,
      balancesModal: false,
      contractModal: false,
      contracts: [],
    };
  },
  computed: {
    ...mapGetters({
      role: "authentication/role",
    }),
  },
  methods: {
    async seeWallet(item) {
      const res = await this.$actions.allStudentsBalance({
        dispatch: "deposit/setBalances",
        publicKey: item.publicKey,
      });
      if (!res.balances?.length)
        return message.error("Student has to balances");

      this.balance = res;
      this.balancesModal = true;
    },
    async seeContractBalance(item) {
      this.contracts = await this.$actions.getStudentsContractBalance({
        studentPublicKey: item.publicKey,
      });
      this.contractModal = true;
    },
    async initTransfers() {
      await this.$refs.transferTable.init({});
    },
    async deleteTransfer(item) {
      const res = await this.$actions.deleteTransfer({
        transferId: item.transferId,
      });
      if (res) return this.initTransfers();
    },
    changeConsultantModal(item) {
      this.$refs.changeConsultant.open(item);
    },
  },
};
</script>

<style scoped></style>
