<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-bind="attrs" v-on="on">
        <slot name="activator" />
      </div>
    </template>

    <v-date-picker
      v-model="date"
      color="primary"
      type="month"
      no-title
      scrollable
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
      <v-btn text color="primary" @click="sendDate"> OK </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import moment from "moment";

export default {
  name: "DatePicketMonth",
  data() {
    return {
      menu: false,
      date: null,
    };
  },
  methods: {
    sendDate() {
      this.$emit("onOk", {
        year: moment(this.date).year(),
        month: moment(this.date).month() + 1,
      });
      this.menu = false;
      this.date = null;
    },
  },
};
</script>

<style scoped></style>
