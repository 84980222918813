<template>
  <v-dialog width="600px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4" v-if="balance">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          `${balance.fullName} ${$_t("attribute.balances")}`
        }}</span>
        <v-spacer></v-spacer>
        <DatePicketMonth @onOk="exportBalances">
          <template #activator>
            <v-btn color="primary" outlined>{{
              $_t("attribute.export")
            }}</v-btn>
          </template>
        </DatePicketMonth>

        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list-item v-for="(balance, index) in balance.balances" :key="index">
        <v-list-item-content>
          <v-list-item-content>
            {{
              `${$_t("attribute.classroomName")} : ${balance.classroomName}`
            }} </v-list-item-content
          ><v-list-item-content>
            {{ `${$_t("attribute.teacherName")} : ${balance.teacherFullName}` }}
          </v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.classroomType")} : ${balance.classroomType}` }}
          </v-list-item-content>
          <v-list-item-content>
            {{
              `${$_t("attribute.classroomLevel")} : ${$_t(
                `attribute.${balance.courseLevel}`
              )}`
            }}
          </v-list-item-content>
          <v-list-item-content>
            {{
              `${$_t("attribute.baseType")} : ${$_t(
                `attribute.${balance.courseName}`
              )}`
            }} </v-list-item-content
          ><v-list-item-content>
            {{ `${$_t("attribute.amount")} : ${balance.balance}` }}
          </v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.totalPay")} : ${balance.totalPay}` }}
          </v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.totalPayHour")} : ${balance.totalPayHour} H` }}
          </v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.totalUsed")} : ${balance.totalUsed}` }}
          </v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.remaningHour")} : ${balance.remaningHour} H` }}
          </v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.shouldCharge")} : ${balance.shouldCharge}` }}
          </v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.sideCosts")} : ${balance.sideCosts ?? ""}` }}
          </v-list-item-content>
          <v-list-item-content>
            {{
              `${$_t("attribute.totalShouldPayForSideCosts")} : ${
                balance.totalShouldPayForSideCosts ?? ""
              }`
            }}
          </v-list-item-content>
          <v-list-item-content>
            {{
              `${$_t("attribute.totalPaidForSideCosts")} : ${
                balance.totalPaidForSideCosts ?? ""
              }`
            }}
          </v-list-item-content>
          <v-list-item-content>
            {{
              `${$_t("attribute.sideCostsBalance")} : ${
                balance.sideCostsBalance ?? ""
              }`
            }}
          </v-list-item-content>
          <v-list-item-content>
            <v-divider />
          </v-list-item-content>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import FileDownload from "js-file-download";
import DatePicketMonth from "@/packages/finance/components/DatePicketMonth";
export default {
  name: "Balances",
  components: { DatePicketMonth },
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    balance: {
      default: null,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    async exportBalances({ month, year }) {
      await api
        .get(
          urls.qs("exportDeposits", {
            filter: this.balance.fullName,
            month,
            year,
          }),
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          FileDownload(
            res,
            `${year}-${month}-${this.balance.fullName} deposits.xlsx`
          );
        });
    },
  },
};
</script>
