<template>
  <v-dialog width="700px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          $_t("attribute.addGroupSalary")
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <MainSoftForm>
        <template v-slot:body>
          <MyFormGenerator
            lg="10"
            :schema="schema"
            :validator="$v"
            :enter="submit"
          ></MyFormGenerator>
          <v-simple-table style="width: 100%">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    {{ $_t("attribute.teacherFullName") }}
                  </th>
                  <th class="text-left">
                    {{ $_t("attribute.lessonType") }}
                  </th>
                  <th class="text-left">
                    {{ $_t("attribute.monthly") }}
                  </th>
                  <th class="text-left">
                    {{ $_t("attribute.amount") }}
                  </th>
                  <th class="text-left">
                    {{ $_t("attribute.delete") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(teacher, i) in teachers" :key="i">
                  <td data-label="Name">{{ teacher.teacherFullName }}</td>
                  <td>
                    {{ $_t(`attribute.${teacher.lessonType}`) }}
                  </td>
                  <td>
                    {{ teacher.totalMonthlyTeachingHour + " h" }}
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      dense
                      hide-details
                      v-model="teacher.settlementAmount"
                    />
                  </td>
                  <td>
                    <v-btn small @click="deleteTeacher(i)" dark color="red" fab>
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
        <template v-slot:action>
          <v-col cols="12">
            <div class="d-flex justify-end">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                {{ $_t("attribute.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                class="ml-4"
                @click="submit"
                :loading="is_loading"
                :disabled="is_loading"
              >
                {{ $_t("attribute.add") }}
              </v-btn>
            </div>
          </v-col>
        </template>
      </MainSoftForm>
    </v-card>
  </v-dialog>
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import MyFormGenerator from "@/components/app/form/MyFormGenerator";
import { mapGetters } from "vuex";
import { ADD_GROUP_SALARY } from "@/packages/finance/schema/ADD_GROUP_SETTLEMENT.schema";
import message from "@/tools/Message";
export default {
  name: "AddGroupSalary",
  components: { MyFormGenerator, MainSoftForm },
  props: {
    teachersPublicKey: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      modal: false,
      schema: ADD_GROUP_SALARY.schema,
      form: ADD_GROUP_SALARY.model,
      teachers: [],
      toTime: null,
    };
  },
  validations: {
    ...ADD_GROUP_SALARY.validations,
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },
  watch: {
    async "form.toTime"(val) {
      if (!val) return;
      const { teachersSettlementsInfo, toTime } =
        await this.$actions.getGuideForGroupCreateSettlement({
          toTime:
            new Date(`${this.form.toTime.split("T")[0]}T23:59:59`).getTime() /
            1000,
          baseType: null,
          teachersPublicKey: this.teachersPublicKey,
        });
      this.teachers = [...teachersSettlementsInfo];
      this.toTime = toTime;
    },
    modal(val) {
      if (!val) this.clear();
    },
  },
  methods: {
    deleteTeacher(index) {
      this.teachers.splice(index, 1);
    },
    async submit() {
      if (!this.isFormValidate()) return;
      if (!this.teachers.length) return message.error(this.$_t("EMPTY_ITEM"));
      console.log(this.teachers);
      const res = await this.$actions.createGroupSettlement({
        toTime: this.toTime,
        teachersSettlementsInfo: [...this.teachers],
      });
      if (res) {
        this.close();
        this.$emit("update");
      }
    },
    open() {
      this.modal = true;
    },
    close() {
      this.modal = false;
    },
    clear() {
      this.form.toTime = null;
      this.toTime = null;
      this.teachers = [];
    },
  },
};
</script>

<style scoped></style>
