const { required, decimal } = require("vuelidate/lib/validators");

export const ADD_TRANSFER = {
  /* Form SCHEMA */
  schema: [
    {
      id: "sourceClassroom",
      label: "sourceClassroom",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "select",
      text: (item) =>
        `${item.classroomName || ""} ${
          item.classroomState === "Archived"
            ? "| Archived"
            : item.classroomState === "Deleted"
            ? "| Deleted"
            : ""
        } | ${item.balanceInHour} h | ${item.balance} | ${
          item.teacherFullName || ""
        }`,
      value: "classroomId",
      items: [],
      returnObject: true,
      show: true,
    },
    {
      id: "destinationClassroom",
      label: "destinationClassroom",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "select",
      text: (item) =>
        `${item.classroomName || ""} ${
          item.classroomState === "Archived"
            ? "| Archived"
            : item.classroomState === "Deleted"
            ? "| Deleted"
            : ""
        } | ${item.balanceInHour} h | ${item.balance} | ${
          item.teacherFullName || ""
        }`,
      returnObject: true,
      value: "classroomId",
      items: [],
      show: true,
    },
    {
      id: "amount",
      label: "amount",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "text",
    },
    {
      id: "description",
      label: "description",
      placeholder: "",
      cols: 12,
      lg: 12,
      md: 12,
      sm: 12,
      type: "textarea",
    },
  ],

  /* Form MODEL */
  model: {
    sourceClassroom: null,
    destinationClassroom: null,
    amount: null,
    type: "Classroom",
    description: null,
  },

  /* VALIDATION SCHEMA */
  validations: {
    form: {
      destinationClassroom: {
        required,
      },
      sourceClassroom: {
        required,
      },
      amount: {
        required,
        decimal,
      },
      description: {},
    },
  },
};
