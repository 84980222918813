<template>
  <div>
    <v-row class="justify-space-between">
      <v-col cols="6" sm="4" class="pt-5">
        <TopTitle :title="$_t(`attribute.${role}s`)" />
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
    <v-row>
      <v-col cols="12">
        <MainTable
          @search="initUsers({ search: $event })"
          :headers="headers"
          :items="users"
          :page-info="pageInfo"
          :loading="is_loading"
          :show-select="role === 'Teacher'"
        >
          <template v-slot:fullName="{ item }">
            <div class="d-flex justify-start align-center">
              <v-avatar size="35" class="mr-2" color="primary">
                <span class="white--text text-capitalize">
                  {{ Array.from(item.fullName)[0] }}
                </span>
              </v-avatar>
              <span>{{ item.fullName }}</span>
            </div>
          </template>
          <template v-slot:baseType="{ item }">
            <span v-if="['Admin', 'Teacher', 'TeamLeader'].includes(item.role)">
              <template v-if="item.baseType">
                <span v-for="(type, index) in item.baseType" :key="type">
                  {{ $_t(`attribute.${type}`) }}
                  {{ item.baseType[index + 1] ? ", " : "" }}
                </span>
              </template>
            </span>
          </template>
          <template v-slot:top-right>
            <slot name="exportDeposit">
              <v-btn
                color="primary"
                v-if="role === 'Teacher'"
                @click="menu2 = true"
              >
                {{ $_t("attribute.export") }}
              </v-btn>
              <v-btn
                color="primary"
                class="mx-2"
                v-if="role === 'Teacher'"
                @click="addGroupSalary"
              >
                {{ $_t("attribute.addGroupSalary") }}
              </v-btn>
              <v-btn
                color="primary"
                v-else-if="role === 'Student'"
                @click="menu2 = true"
              >
                {{ $_t("attribute.overallStudentsBalance") }}
              </v-btn>
            </slot>
          </template>
          <template v-slot:edit="{ item }">
            <v-row>
              <v-col cols="12" class="d-flex justify-space-between">
                <slot name="transfer" :item="item"></slot>
                <slot name="changeAcademicConsultant" :item="item"></slot>
                <v-btn
                  v-if="role === 'Student' && !hideAddBalance"
                  color="primary"
                  dark
                  @click="$emit('deposit', item)"
                >
                  {{ $_t("attribute.addDeposit") }}
                </v-btn>
                <v-btn
                  v-if="role === 'Teacher'"
                  color="primary"
                  dark
                  @click="$emit('salary', item)"
                >
                  {{ $_t("attribute.addSalary") }}
                </v-btn>
                <v-btn
                  v-if="role === 'Teacher'"
                  color="primary"
                  dark
                  class="ml-2"
                  @click="$emit('report', item)"
                >
                  {{ $_t("attribute.report") }}
                </v-btn>
                <v-btn
                  v-if="role === 'Teacher'"
                  color="primary"
                  dark
                  class="ml-2"
                  @click="openDate(item)"
                >
                  {{ $_t("attribute.export") }}
                </v-btn>
                <v-btn
                  v-if="role === 'Student'"
                  color="primary"
                  class="ml-2"
                  dark
                  @click="$emit('wallet', item)"
                >
                  {{ $_t("attribute.wallet") }}
                </v-btn>
                <v-btn
                  v-if="role === 'Student'"
                  color="primary"
                  class="ml-2"
                  dark
                  @click="$emit('contract', item)"
                >
                  {{ $_t("attribute.contractBalance") }}
                </v-btn>
                <v-btn
                  v-if="role === 'Teacher'"
                  color="primary"
                  dark
                  class="ml-2"
                  @click="$emit('sum', item)"
                >
                  {{ $_t("attribute.salaryHistory") }}
                </v-btn>
                <v-btn
                  v-if="role === 'Teacher'"
                  color="primary"
                  dark
                  class="ml-2"
                  @click="
                    teacherPublicKey = item.publicKey;
                    showFeeModal = true;
                  "
                >
                  {{ $_t("attribute.teacherFee") }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:state="{ item }">
            <v-chip dark :color="item.state === 'Archived' ? 'red' : 'green'">{{
              item.state
            }}</v-chip>
          </template>
          <template v-slot:key="{ item }">
            <v-simple-checkbox
              :value="selected.includes(item.publicKey)"
              @input="toggleToSelected(item.publicKey)"
            ></v-simple-checkbox>
          </template>
          <template v-slot:pagination>
            <v-pagination
              v-model="pageInfo.page"
              :length="pageInfo.pageCount"
              :total-visible="5"
              @input="initUsers({})"
            ></v-pagination>
          </template>
        </MainTable>
        <v-dialog ref="menu" v-model="menu" width="300px">
          <v-card>
            <v-date-picker
              width="300px"
              v-model="date"
              color="primary"
              type="month"
              elevation="0"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                {{ $_t("attribute.cancel") }}
              </v-btn>
              <v-btn color="primary" outlined @click="$emit('export', item)">
                {{ $_t("attribute.exportAll") }}
              </v-btn>
              <v-btn color="primary" @click="$emit('export', item, date)">
                {{ $_t("attribute.export") }}
              </v-btn>
            </v-date-picker>
          </v-card>
        </v-dialog>
        <v-dialog ref="menu2" v-model="menu2" width="300px">
          <v-card>
            <v-date-picker
              width="300px"
              v-model="date2"
              color="primary"
              elevation="0"
              type="month"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu2 = false">
                {{ $_t("attribute.cancel") }}
              </v-btn>
              <v-btn color="primary" @click="handleExport">
                {{ $_t("attribute.export") }}
              </v-btn>
            </v-date-picker>
          </v-card>
        </v-dialog>
        <AddGroupSalary
          @update="update"
          ref="addGroupSalary"
          :teachers-public-key="selected"
        />
        <UpsertFee
          :is-show-modal="showFeeModal"
          :public-key="teacherPublicKey"
          @close="
            showFeeModal = false;
            teacherPublicKey = null;
          "
          :readonly="['TeamLeader'].includes(authRole)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TopTitle from "@/components/app/TopTitle";
import MainTable from "@/packages/admin/components/MainTable";
import { mapGetters } from "vuex";
import { reactive, ref } from "vue";
import { USER_TABLE_SECTION_SCHEMA } from "@/packages/finance/schema/USER_TABLE_SECTION_HEADER";
import api from "@/tools/Api";
import urls from "@/tools/Urls";
import FileDownload from "js-file-download";
import moment from "moment";
import AddGroupSalary from "@/packages/finance/components/settlement/AddGroupSalary";
import message from "@/tools/Message";
import UpsertFee from "@/packages/finance/components/teacherFee/UpsertFee";

export default {
  name: "UsersTableSection",
  components: { UpsertFee, AddGroupSalary, MainTable, TopTitle },
  props: {
    role: {
      type: String,
      required: true,
    },
    hideAddBalance: {
      type: Boolean,
      defaults: false,
    },
  },
  computed: {
    ...mapGetters({
      users: "admin/get_users",
      is_loading: "loading/is_loading",
      authRole: "authentication/role",
    }),
  },
  data() {
    return {
      search: "",
      menu: false,
      menu2: false,
      date: null,
      date2: null,
      item: null,
      addDepositModal: false,
      selected: [],
      showFeeModal: false,
      teacherPublicKey: null,
    };
  },
  setup(props) {
    const lessonType = ref(null);
    const baseType = ref(null);
    const headers = USER_TABLE_SECTION_SCHEMA[props.role];
    const pageInfo = reactive({
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
    });
    return {
      lessonType,
      baseType,
      headers,
      pageInfo,
    };
  },
  methods: {
    toggleToSelected(key) {
      const index = this.selected.indexOf(key);

      if (index === -1) {
        this.selected.push(key);
      } else {
        this.selected.splice(index, 1);
      }
    },
    setAllItems() {
      if (this.selected.length) {
        this.selected = [];
      } else {
        this.selected = this.users.map((u) => u.publicKey);
      }
    },
    addGroupSalary() {
      if (!this.selected.length) return message.error("Please select teacher");
      this.$refs.addGroupSalary.open();
    },
    update() {
      this.$emit("update");
    },
    openDate(item) {
      this.item = item;
      this.menu = true;
    },
    async initUsers({ search = this.search }) {
      this.search = search;
      const { pageCount, users } = await this.$actions.getUsers({
        page: this.pageInfo.page,
        limit: this.pageInfo.itemsPerPage,
        role: this.role,
        search: this.search,
        all: true,
      });
      if (this.role === "Teacher") {
        this.selected = users.map((t) => t.publicKey);
      }
      await this.$store.dispatch("admin/setUsers", users);
      this.pageInfo.pageCount = pageCount;
    },
    editDeposit(item) {
      console.log(item);
    },
    close() {
      this.addDepositModal = false;
    },
    handleExport() {
      this.role === "Teacher"
        ? this.exportTeachersDeposit()
        : this.exportStudentsDeposit();
    },
    async exportStudentsDeposit() {
      await api
        .get(
          urls.qs("exportStudentsDeposit", {
            filter: this.search,
            year: moment(this.date2).year(),
            month: moment(this.date2).month() + 1,
          }),
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          FileDownload(res, `${this.date2}-StudentDeposits.xlsx`);
        });
      this.date2 = null;
      this.menu2 = false;
    },
    async exportTeachersDeposit() {
      await api
        .get(
          urls.qs("exportTeachersDeposit", {
            filter: this.search,
            year: moment(this.date2).year(),
            month: moment(this.date2).month() + 1,
          }),
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          FileDownload(res, `${this.date2}-OverallTeachingSalary.xlsx`);
        });
      this.date2 = null;
      this.menu2 = false;
    },
  },
  created() {
    this.initUsers({});
  },
};
</script>
