<template>
  <v-dialog
    width="600px"
    v-model="isShowModal"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div style="width: auto" v-on="on" v-bind="attrs">
        <slot name="activator" />
      </div>
    </template>
    <template #default>
      <v-card class="px-4">
        <v-toolbar class="elevation-0" color="transparent">
          <span class="text-h5 font-weight-bold">{{
            $_t("attribute.changeConsultant")
          }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <MainSoftForm>
          <template v-slot:body>
            <v-select
              hide-details
              flat
              outlined
              class="elevation-0"
              background-color="inputColor"
              :label="$_t('attribute.academicConsultants')"
              dense
              :items="consultants"
              v-model="user.creatorPublicKey"
              :item-text="(item) => `${item.fullName} | ${item.nickName}`"
              item-value="publicKey"
            >
            </v-select>
          </template>
          <template v-slot:action>
            <v-col cols="12">
              <div class="d-flex justify-end">
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  {{ $_t("attribute.cancel") }}
                </v-btn>

                <v-btn
                  color="primary"
                  class="ml-4"
                  @click="submit"
                  :loading="is_loading"
                >
                  {{ $_t("attribute.edit") }}
                </v-btn>
              </div>
            </v-col>
          </template>
        </MainSoftForm>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import MainSoftForm from "@/components/app/form/MainSoftForm";
import { mapGetters } from "vuex";
export default {
  name: "ChangeConsultant",
  components: { MainSoftForm },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
  },
  data() {
    return {
      isShowModal: false,
      user: {},
      consultants: [],
    };
  },
  methods: {
    async submit() {
      const consultant = this.consultants.find(
        (c) => c.publicKey === this.user.creatorPublicKey
      );
      // console.log(consultant);
      // console.log(this.user);
      const res = await this.$actions.editUser({
        ...this.user,
        creatorName: `${consultant.fullName} | ${consultant.nickName}`,
        publicKey: this.user.publicKey,
      });
      if (res) {
        this.isShowModal = false;
        this.$emit("update");
      }
    },
    async open(item) {
      this.user = { ...item };
      this.consultants = await this.$actions.getAcademicConsultants();
      this.isShowModal = true;
    },
    close() {
      this.isShowModal = false;
    },
  },
};
</script>

<style scoped></style>
