<template>
  <v-dialog width="600px" v-model="modal" transition="dialog-bottom-transition">
    <v-card class="px-4" v-if="contracts.length">
      <v-toolbar class="elevation-0" color="transparent">
        <span class="text-h5 font-weight-bold">{{
          `${$_t("attribute.contracts")}`
        }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list-item v-for="(contract, index) in contracts" :key="index">
        <v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.studentName")} : ${contract.studentName}` }}
          </v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.paid")} : ${contract.paid}` }}
          </v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.hasNotPaid")} : ${contract.hasNotPaid}` }}
          </v-list-item-content>
          <v-list-item-content>
            {{ `${$_t("attribute.balance")} : ${contract.balance}` }}
          </v-list-item-content>
          <v-list-item-content>
            <v-divider />
          </v-list-item-content>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Contracts",
  props: {
    isShowModal: {
      type: Boolean,
      default: false,
    },
    contracts: {
      type: [Array],
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
    }),
    modal: {
      get: function () {
        return this.isShowModal;
      },
      set: function (value) {
        if (!value) this.close();
      },
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
