<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :page.sync="pageInfo.page"
      :items-per-page="pageInfo.itemsPerPage"
      hide-default-footer
      class="elevation-0 rounded"
      @page-count="pageInfo.pageCount = $event"
      :loading="loading"
      @click:row="$emit('rowClick', $event)"
    >
      <template v-slot:top>
        <v-container>
          <v-row>
            <v-col cols="12" md="6" v-if="!hideSearch">
              <v-text-field
                :label="$_t('admin.components.admin_actions.search')"
                @input="$emit('search', $event)"
                clearable
                outlined
                dense
                append-icon="mdi-magnify"
                single-line
                hide-details
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <slot name="top-right"></slot>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template v-slot:header.key="{ header }">
        <slot name="header.key" :header="header" />
      </template>
      <template v-slot:item.key="{ item }">
        <span class="text-capitalize">
          <slot name="key" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.fullName="{ item }">
        <span class="text-capitalize">
          <slot name="fullName" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.permissions="{ item }">
        <span class="text-capitalize">
          <slot name="permissions" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.role="{ item }">
        <span class="text-capitalize">
          <slot name="role" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.lessons="{ item }">
        <span class="text-capitalize">
          <slot name="lessons" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.level="{ item }">
        <span class="">
          <slot name="level" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.teachersList="{ item }">
        <span class="text-capitalize">
          <slot name="teachers" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.students="{ item }">
        <span class="text-capitalize">
          <slot name="students" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.createMoment="{ item }">
        <span class="">
          <slot name="createMoment" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="d-flex justify-end">
          <slot name="task" :item="item"></slot>
          <template
            v-if="
              [
                'Admin',
                'TeamLeader',
                'AcademicConsultant',
                'SchedulingAdmin',
                'FinancialAdmin',
                'AssistantTeacher',
              ].includes(role)
            "
          >
            <slot name="actions">
              <v-btn
                @click="$emit('detail', item)"
                color="#4340DA1F"
                class="px-7 text--purple elevation-0"
                :disabled="is_loading"
              >
                <div class="txt-purple">
                  {{ $_t("admin.components.main_table.details") }}
                </div>
              </v-btn>

              <v-btn
                text
                v-if="!hideDelete"
                color="red"
                :disabled="is_loading"
                @click="showVerifyModal(item)"
                class="ml-3"
              >
                <v-icon>mdi-delete-outline</v-icon>
              </v-btn>
            </slot>
          </template>
        </div>
        <!--        <slot name="actions" :item="item"></slot>-->
      </template>
      <template v-slot:item.totalProgress="{ item }">
        <span>
          <slot name="progress" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.totalPay="{ item }">
        <span>
          <slot name="totalPay" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.action="{ item }">
        <span>
          <slot name="action" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.description="{ item }">
        <span>
          <slot name="description" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.baseType="{ item }">
        <span>
          <slot name="baseType" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.times="{ item }">
        <span>
          <slot name="times" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.type="{ item }">
        <span>
          <slot name="type" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.financialState="{ item }">
        <span>
          <slot name="financialState" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.submitMoment="{ item }">
        <span>
          <slot name="submitMoment" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.createMoment="{ item }">
        <span>
          <slot name="createMoment" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.edit="{ item }">
        <span>
          <slot name="edit" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.from="{ item }">
        <span>
          <slot name="from" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.to="{ item }">
        <span>
          <slot name="to" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.state="{ item }">
        <span>
          <slot name="state" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.detail="{ item }">
        <span>
          <slot name="detail" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.paymentMethod="{ item }">
        <span>
          <slot name="paymentMethod" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.depositType="{ item }">
        <span>
          <slot name="depositType" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.confirm="{ item }">
        <span>
          <slot name="confirm" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.confirmationDate="{ item }">
        <span>
          <slot name="confirmationDate" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.delete="{ item }">
        <span>
          <slot name="delete" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.archive="{ item }">
        <span>
          <slot name="archive" :item="item"></slot>
        </span>
      </template>
      <template v-slot:item.file="{ item }">
        <span>
          <slot name="file" :item="item"></slot>
        </span>
      </template>
    </v-data-table>
    <v-dialog v-model="isShowVerifyModal" persistent max-width="500">
      <v-card>
        <v-toolbar class="elevation-0" color="transparent">
          <v-btn fab color="red lighten-5" small class="elevation-0 mr-3">
            <v-icon color="red">mdi-delete-outline</v-icon>
          </v-btn>
          <span class="text-h5 font-weight-bold">
            {{ $_t("attribute.delete") }}
          </span>
          <v-spacer></v-spacer>
          <v-btn icon @click="cancel">
            <v-icon color="red">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="text-h5 text-break">
          {{ $_t("attribute.delete_sure") }}
        </v-card-title>
        <v-card-text class="red--text">
          {{ $_t("attribute.action_is_permanent") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="cancel"> {{ $_t("attribute.cancel") }} </v-btn>
          <v-btn color="red darken-1" dark @click="confirm">
            {{ $_t("attribute.delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="text-center pt-2">
      <slot name="pagination" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MainTable",
  computed: {
    ...mapGetters({
      is_loading: "loading/is_loading",
      role: "authentication/role",
    }),
  },
  props: {
    headers: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    pageInfo: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideSearch: {
      default: false,
      type: Boolean,
    },
    hideDelete: {
      default: false,
      type: Boolean,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShowVerifyModal: false,
      item: null,
      selected: [],
    };
  },
  methods: {
    showVerifyModal(item) {
      this.item = item;
      this.isShowVerifyModal = true;
    },
    cancel() {
      this.isShowVerifyModal = false;
    },
    confirm() {
      if (this.item) this.$emit("delete", this.item);
      this.cancel();
    },
  },
};
</script>

<style scoped>
.txt-purple {
  color: #4340da !important;
}
</style>
